import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uu.diva-portal.org/smash/record.jsf?aq2=%5B%5B%5D%5D&c=2&af=%5B%5D&searchType=SIMPLE&sortOrder2=title_sort_asc&query=Aleksander+Okonski&language=en&pid=diva2%3A1189583&aq=%5B%5B%5D%5D&sf=all&aqe=%5B%5D&sortOrder=author_sort_asc&onlyFullText=false&noOfRows=50&dswid=-9131"
        }}>{`Implementing Security Rules, Safeguards, and IPS tools for Private Cloud Infrastructures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uu.diva-portal.org/smash/record.jsf?dswid=-6830&pid=diva2%3A773518&c=3&searchType=SIMPLE&language=en&query=Aleksander+Okonski&af=%5B%5D&aq=%5B%5B%5D%5D&aq2=%5B%5B%5D%5D&aqe=%5B%5D&noOfRows=50&sortOrder=author_sort_asc&sortOrder2=title_sort_asc&onlyFullText=false&sf=all"
        }}>{`Privacy Concerns in Android Devices`}</a></li>
    </ul>
    <h6>{`External reference for:`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uu.diva-portal.org/smash/record.jsf?aq2=%5B%5B%5D%5D&c=1&af=%5B%5D&searchType=SIMPLE&sortOrder2=title_sort_asc&query=Aleksander+Okonski&language=en&pid=diva2%3A1325392&aq=%5B%5B%5D%5D&sf=all&aqe=%5B%5D&sortOrder=author_sort_asc&onlyFullText=false&noOfRows=50&dswid=2841"
        }}>{`Surveillance Using Facial Recognition and Social Media Data`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      